h5 {
  text-align: center;
}

.back {
  color: var(--text-tertiary);
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.txt-box-small {
  width: 20%;
}

.txt-box-medium {
  width: 48%;
}

.txt-box-large {
  width: 70%;
}

.form-control {
  min-width: 150px;
}

.general-input,
.txt-box,
.txt-box-small,
.txt-box-large {
  margin-bottom: 15px !important;
}


@media (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 10px;
  }

  .general-input,
  .txt-box-small,
  .txt-box-medium,
  .txt-box-large {
    width: 100%;
  }
}